<template>
  <div class="publicStyle">
    <p class="publicTitleStyle">产品声量概览
      <div>
        <svg-icon
            icon-class="downloadIcon"
            class-name="downloadIcon"
        ></svg-icon>
        <el-button
            class="noborderBtn"
            type="text"
            style="margin-right:0;color:#000;"
            @click="downloadGeneralView"
        >
          下载
        </el-button>
      </div></p>
    <div class="generalViewTable">
      <el-table
        :header-cell-style="generalViewTableHeadClass"
        :row-class-name="rowStyleFunc"
        :data="tableData"
        v-loading="viewLoading"
      >
        <el-table-column width="100" prop="name" align="center" label="产品">
          <template #default="{ row }">
            {{ this.platformToZn(row.name) }}
          </template>
        </el-table-column>
        <el-table-column prop="exposure" align="center" label="曝光量">
          <template #default="{ row }">
            {{ sliceDecimal(row.exposure) }}
          </template>
        </el-table-column>
        <el-table-column prop="engagementQs" align="center" label="互动量">
          <template #default="{ row }">
            {{ sliceDecimal(row.engagementQs) }}
          </template>
        </el-table-column>
        <el-table-column prop="pgcNum" align="center" label="PGC发帖数量">
          <template #header>
            <div>
              <p>PGC</p>
              <P>发帖数量</P>
            </div>
          </template>
          <template #default="{ row }">
            {{ sliceDecimal(row.pgcNum) }}
          </template>
        </el-table-column>
        <el-table-column prop="ugcNum" align="center" label="UGC发帖数量">
          <template #header>
            <div>
              <p>UGC</p>
              <P>发帖数量</P>
            </div>
          </template>
          <template #default="{ row }">
            {{ sliceDecimal(row.ugcNum) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="pgcExposurePct"
          align="center"
          label="PGC曝光占比"
        >
          <template #header>
            <div>
              <p>PGC</p>
              <P>曝光占比</P>
            </div>
          </template>
          <template #default="{ row }">
            {{ sliceDecimal(row.pgcExposurePct) }}
          </template>
        </el-table-column>
        <el-table-column prop="pgcExposure" align="center" label="PGC曝光量">
          <template #header>
            <el-tooltip effect="dark" placement="top">
              <template #content>
                <div>PGC投放数占比</div>
              </template>
              <div>
                <p>PGC</p>
                <p>曝光量</p>
              </div>
            </el-tooltip>
          </template>
          <template #default="{ row }">
            {{ sliceDecimal(row.pgcExposure) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="pgcEngagementQs"
          align="center"
          label="PGC互动量"
        >
          <template #header>
            <div>
              <p>PGC</p>
              <P>互动量</P>
            </div>
          </template>
          <template #default="{ row }">
            {{ sliceDecimal(row.pgcEngagementQs) }}
          </template>
        </el-table-column>
        <el-table-column prop="cost" align="center" label="投放费用">
          <template #default="{ row }">
            {{
              row.cost === "--"
                ? "--"
                : "¥ " + sliceDecimal(Math.round(row.cost - 0))
            }}
          </template>
        </el-table-column>
        <el-table-column width="100" prop="cpm" align="center" label="CPM">
          <template #default="{ row }">
            {{ row.cpm === "--" ? "--" : "¥ " + row.cpm }}
          </template>
        </el-table-column>
        <el-table-column width="100" prop="cpe" align="center" label="CPE">
          <template #default="{ row }">
            {{ row.cpe === "--" ? "--" : "¥ " + row.cpe }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { downloadGeneralView } from "@/api/sov/sovProduct";
import { mapState } from "vuex";

export default {
  data() {
    return {
      generalViewTableHeadClass: {
        "background-color": "#F6F8FA",
        "font-size": "14px",
      },
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    timeRange: {
      type: String,
      default: "",
    },
    productId: {
      type: String,
      default: "",
    },
    viewLoading: {
      type: Boolean,
      default: false,
    },
    segValue: {
      type: String,
      default: "32_1",
    },
  },
  computed: {
    ...mapState(["productsSeg", "productSeg"]),
  },
  methods: {
    rowStyleFunc() {
      return "otherStyle";
    },
    // rowStyleFunc({ rowIndex }) {
    //   console.log(rowIndex);
    //   if (rowIndex === 0) {
    //     return "firstRowStyle";
    //   } else {
    //     return "otherStyle";
    //   }
    // },
    downloadGeneralView() {
      console.log(this.$route.path);
      console.log(this.productSeg);
      const params = {
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.productId,
        seg:
          this.$route.path === "/sovProductCompare"
            ? this.productsSeg
            : this.productSeg,
        // aggMonth: this.timeRange[1],
      };
      this.$message({
        type: "info",
        message: "文件正在解析，请耐心等待",
      });
      downloadGeneralView(params)
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.publicStyle {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .publicTitleStyle {
    padding:20px 20px 0 20px;
    font-size: 20px;
    color: #4f4f4f;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .downBtn {
    overflow: hidden;

    span {
      width: 64px;
      height: 28px;
      float: right;
      margin-right: 20px;
      text-align: left;
      padding-left: 10px;
      line-height: 28px;
      background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;

      .downIconClass {
        font-size: 16px;
        position: relative;
        left: 6px;
      }
    }
  }

  .generalViewTable {
    margin-top: 10px;
  }
}
</style>

<style lang="scss">
.generalViewTable {
  .firstRowStyle {
    td div {
      font-size: 16px;
      color: #393a4d;
      font-weight: 500;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }
}
</style>
