<template>
  <div class="sovProductWrap">
    <div class="filterBoxFixed">
      <sovTitle cardTitle="时间范围" type="product"></sovTitle>
    </div>
    <div class="filterRightBlock">
      <sovGeneralView
          style="margin-top: 0px"
          title="产品声量概览"
          :viewLoading="viewLoading"
          :segValue="generalViewSegValue"
          :productId="id"
          :timeRange="timeRange"
          :tableData="generalViewTableData"
      ></sovGeneralView>
      <productTa
          title="投放TA触发效率"
          :viewLoading="productTaLoading"
          :productId="id"
          :timeRange="timeRange"
          :tableData="productTaTableData"
          @changeGeneralSeg="changeGeneralSeg"
      ></productTa>
      <sovDistribute></sovDistribute>
      <sovChartCart
          title="产品表现趋势"
          @timeSel="timeSel"
          @seeTotalChart="seeTotalChart"
          @seeBrandChart="seeBrandChart"
          :campaignList="cateList"
          :echartLegend="echartLegend"
          :chartData="chartData"
      >
      </sovChartCart>
      <el-card style="margin-bottom: 21px; border-radius: 10px">
        <h2
            style="font-size: 20px; margin-bottom: 20px; color: #4f4f4f"
            class="insight_title"
        >
          产品社媒人群画像
        </h2>
        <div
            style="display: flex; justify-content: space-between; flex-wrap: wrap"
        >
          <stagger-x-bar
              style="width: 48%; height: 260px; margin-bottom: 50px"
              v-for="(item, i) in profileData"
              :ref="'staggerXBar' + i"
              :key="item.title"
              :title="item.title"
              :xAxisData="item.xAxisData"
              :yAxisData="item.yAxisData"
              :seriesData="item.seriesData"
              :color="
            item.title === '性别占比'
              ? ['rgb(83, 186, 210)', '#e893a8']
              : item.title === '城市级别占比'
              ? ['#32a3be', 'rgb(83, 186, 210)', '#e893a8', '#e37893']
              : [
                  '#2c7d98',
                  '#32a3be',
                  'rgb(83, 186, 210)',
                  '#d1eef8',
                  '#f0a3b6',
                  '#e37893',
                  '#a02947',
                ]
          "
          />
        </div>
      </el-card>
      <sovKolContent
          @setSelPlatform="setSelPlatform"
          @clickHotWords="clickHotWords"
          @downContent="downContent"
          @loadMoreData="loadMoreData"
          @changeSeg="changeSeg"
          title="产品PGC内容分析"
          :brandTab="product"
          :productHotwordsList="productHotwordsList"
          :commentHotwordsList="commentHotwordsList"
          :articleTopLoading="articleTopLoading"
          :tableData="kolContentTableDataCopy"
          :btnLoadingflag="btnLoadingflag"
      >
      </sovKolContent>
    </div>
  </div>
</template>

<script>
import sovTitle from "../sovCommonComponents/sovTitle";
import sovGeneralView from "./sovProductComponents/sovGeneralView";
import productTa from "./sovProductComponents/productTa";
// import sovDistribute from "../sovCommonComponents/sovDistribute";
import sovDistribute from "./sovProductComponents/sovBrandStructure";
import sovChartCart from "./sovProductComponents/sovChartCart";
import sovKolContent from "./sovProductComponents/sovKolContent";
import staggerXBar from "@/components/echarts/staggerXBar";
import {
  getProductOverview,
  getProductTrend,
  getProductArticleTop,
  getProductHotwords,
  getProductMonth,
  downLoadProductArticleReport,
  getCampaignInsightProfile,
  getSovProductPlatformTa,
} from "@/api/sov/sovProduct";
import {
  setStartTime,
  setEndTime,
  setStartWeekTime,
  setEndWeekTime,
  setStartMonthTime,
  setEndMonthTime,
} from "../sovCommonFunc/index";
import { mapState } from "vuex";

export default {
  data() {
    return {
      generalViewTableData: [],
      productTaTableData: [],
      viewLoading: false,
      productTaLoading: false,
      distributeRadioType: "percent",
      yType: "exposure",
      trendType: "exposure",
      tempType: "exposure",
      echartsTime: "day",
      echartLegend: [],
      cateList: [],
      originLegend: [],
      originSeries: [],
      chartData: {
        echartxAxis: {
          data: [
            201905, 201906, 201907, 201908, 201909, 201910, 201911, 201912,
            202001, 202002,
          ],
          type: "category",
        },
        echartSeries: [
          {
            data: [
              73079874, 73584250, 55022549, 62489669, 54538919, 42380599,
              28357890, 42266074, 53824757, 38509705,
            ],
            name: "",
            type: "line",
            smooth: true,
          },
        ],
        echartColor: ["rgb(83, 186, 210)", "#e37893", "#daae3c"],
      },
      defaultColors: [
        "#2c7d98",
        "#d1eef8",
        "#f0a3b6",
        "#a02947",
        "#06c1ff",
        "#65aeb1",
        "#bda29a",
        "#ff7c7c",
        "#ffd3d3",
        "#db7cae",
        "#d3baed",
        "#966767",
        "#4fe3ff",
        "#ffa86d",
        "#6797fe",
        "#6c80fe",
        "#ffb57e",
        "#4ebcb4",
      ],
      originData: [],
      kolContentTableData: [],
      hotword: "",
      segValue: "32_1",
      generalViewSegValue: "32_1",
      selPlatform: "all",
      searchInput: null,
      productHotwordsList: [],
      commentHotwordsList: [],
      articleTopLoading: false,
      timeMonth: "",
      timeYear: "",
      profileData: [],
      btnLoadingflag: false,
      kolContentTableDataCopy: [],
      page: 1,
      size: 20,
      total: 0,
    };
  },
  props: {
    product: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    duration: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["productSeg"]),
    timeRange() {
      if (this.$route.query.duration) {
        return JSON.parse(this.$route.query.duration);
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getProductMonth();
      this.getProductOverview();
      this.getProductTrend();

      this.getProductHotwords();
      this.getProfile();
      this.getProductArticleTop();
      this.getSovProductPlatformTaFunc();
    },
    // 投放TA触达效率
    async getSovProductPlatformTaFunc() {
      this.productTaLoading = true;
      let res = await getSovProductPlatformTa({
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.id,
        seg: this.productSeg,
      });
      if (res.code === 0) {
        this.formatNull(res.data);
        this.productTaTableData = res.data;
        this.productTaLoading = false;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    // 人群画像
    async getProfile() {
      const { data } = await getCampaignInsightProfile({
        id: this.id,
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        // aggMonth: this.timeRange[1],
      });
      const yAxisData = [
        "wechat",
        "weibo",
        "xiaohongshu",
        "douyin",
        "bilibili",
      ];
      this.profileData = data.map((item) => {
        const arr = [];
        const temp = {
          yAxisData,
        };
        switch (item.name) {
          case "age":
            temp.title = "年龄占比";
            break;
          case "gender":
            temp.title = "性别占比";
            break;
          case "income":
            temp.title = "收入水平占比";
            break;
          case "city_tier":
            temp.title = "城市级别占比";
            break;
        }
        temp.xAxisData = item.profile.map((temp) => temp.label);
        const keyArr = item.profile.map((temp) => temp.id);
        yAxisData.forEach((platform) => {
          const temp = [];
          keyArr.forEach((key) => {
            temp.push(
              item.proportionMap[platform] && item.proportionMap[platform][key]
                ? Math.floor(item.proportionMap[platform][key] * 100)
                : null
            );
          });
          const index = temp.findIndex((item) => item != null);
          if (index !== -1) {
            // 更改temp的最后一个值以保证temp每项相加和为100
            let endValue = 100;
            temp.slice(0, -1).forEach((item) => {
              endValue = endValue - item;
            });
            temp[temp.length - 1] = endValue <= 0 ? 0 : endValue;
          }
          arr.push(temp);
        });
        // 转置
        temp.seriesData = arr[0].map((col, i) => arr.map((row) => row[i]));
        return temp;
      });
    },
    async getProductMonth() {
      let res = await getProductMonth();
      if (res.code === 0) {
        this.timeMonth = res.data.split("-")[1];
        this.timeYear = res.data.split("-")[0];
      } else {
        this.$message.error("获取时间失败，请联系管理员!");
      }
    },
    async getProductOverview() {
      this.viewLoading = true;
      let res = await getProductOverview({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.id,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.formatNull(res.data);
        this.generalViewTableData = res.data;
        this.viewLoading = false;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },

    async getProductHotwords() {
      let res = await getProductHotwords({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.id,
        // aggMonth: this.timeRange[1],
      });
      console.log(res, "热词");
      if (res.code === 0) {
        if (res.data !== null) {
          // 产品热词临时数组
          let temparr = [];
          // 评论热词临时数组
          let temparr1 = [];
          console.log(res.data);
          let obj = res.data.categoryHotwords;
          let obj1 = res.data.commentsHotwords;
          for (let j in obj1) {
            temparr1.push({
              name: j,
              value: obj1[j],
            });
          }
          for (let k in obj) {
            temparr.push({
              name: k,
              value: obj[k],
            });
          }
          temparr1.sort((a, b) => {
            return b.value - 0 - (a.value - 0);
          });
          temparr.sort((a, b) => {
            return b.value - 0 - (a.value - 0);
          });
          this.productHotwordsList = temparr.slice(0, 40);
          this.commentHotwordsList = temparr1.slice(0, 40);
        } else {
          this.productHotwordsList = [];
          this.commentHotwordsList = [];
        }
        console.log(this.productHotwordsList, this.commentHotwordsList);
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    async getProductArticleTop() {
      this.articleTopLoading = true;
      let res = await getProductArticleTop({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.id,
        searchWord: "",
        platform: this.selPlatform,
        sortColumn: "exposure",
        sortDirection: "desc",
        hotword: this.hotword,
        page: this.page,
        size: 20,
        seg: this.productSeg,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.kolContentTableData = res.data.adsTopArticles;
        this.kolContentTableDataCopy.push(...this.kolContentTableData);
        this.articleTopLoading = false;
        this.total = res.data.total;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    async getProductTrend() {
      let res = await getProductTrend({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.id,
        xType: this.echartsTime,
        yType: this.yType,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        console.log(res.code);
        this.originData = [].concat(res.data);
        this.initTrendData(res.data);
        this.originLegend = this.echartLegend;
        this.originSeries = this.chartData.echartSeries;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    initTrendData(data) {
      if (this.trendType === "cpi") {
        this.tempType = "trend";
      } else {
        this.tempType = "exposureTrend";
      }
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      console.log(data);
      data.forEach((campaign, i) => {
        console.log(campaign);
        this.echartLegend.push({
          name: campaign.name,
          color: this.chartData.echartColor.concat(this.defaultColors)[i],
          index: Math.random() * 2 * Math.random(),
        });
        if (campaign.trend.length !== 0) {
          startTimeArr.push(campaign.trend[0].xAxis);
          const lastIndex = campaign.trend.length - 1;
          endTimeArr.push(campaign.trend[lastIndex].xAxis);
        }
      });

      if (this.echartsTime === "day") {
        startTimeArr.push(
          setStartTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        // day
        this.formatDayDate(startTimeArr, endTimeArr);
      } else if (this.echartsTime === "week") {
        // week
        startTimeArr.push(
          setStartWeekTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndWeekTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatWeekDate(startTimeArr, endTimeArr);
      } else {
        // month
        startTimeArr.push(
          setStartMonthTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndMonthTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatMonthDate(startTimeArr, endTimeArr);
      }
    },
    formatDayDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getDayAllDate(startTime, endTime, type);
    },
    getDayAllDate(start, end, type) {
      console.log(start, end);
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp; stamp <= stampDe; stamp += oneDay) {
        dateArr.push(this.timeStampToDate(stamp));
      }
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    formatWeekDate(startTimeArr, endTimeArr, type) {
      const nStartTimeArr = startTimeArr.map((time) => time.slice(0, 10));
      const nEndTimeArr = endTimeArr.map((time) => time.slice(-10));
      const startTimeStamp = nStartTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = nEndTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getWeekAllDate(startTime, endTime, type);
    },
    getWeekAllDate(start, end, type) {
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      let stampArr = [];
      for (stamp; stamp <= stampDe; stamp += oneWeek) {
        stampArr.push(stamp);
      }
      const sixDay = 6 * 24 * 60 * 60 * 1000;
      let dateArr = [];
      dateArr = stampArr.map((stamp) => {
        const weekLastDayStamp = stamp + sixDay;
        return (
          this.timeStampToDate(stamp) +
          " ~ " +
          this.timeStampToDate(weekLastDayStamp)
        );
      });
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    formatMonthDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getMonthAllDate(startTime, endTime, type);
    },
    getMonthAllDate(start, end, type) {
      let startArr = start.split("-");
      let startYear = startArr[0] * 1;
      let startMonth = startArr[1] * 1;
      let endArr = end.split("-");
      let endYear = endArr[0] * 1;
      let endMonth = endArr[1] * 1;
      let dateArr = [];
      if (startYear < endYear) {
        for (startMonth; startMonth <= 12; startMonth++) {
          const tempStr =
            startYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
          if (startMonth === 12 && startYear + 1 < endYear) {
            startYear++;
            startMonth = 0;
          }
        }
        for (let i = 1; i <= endMonth; i++) {
          const tempStr = endYear + "-" + (i < 10 ? "0" + i : i);
          dateArr.push(tempStr);
        }
      } else {
        for (startMonth; startMonth <= endMonth; startMonth++) {
          const tempStr =
            endYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
        }
      }
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    timeStampToDate(timestamp) {
      const date = new Date(timestamp);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },
    getDataFromOrigin() {
      this.chartData.echartSeries = this.originData.map((campaign) => {
        console.log(campaign);
        const seriesItem = {
          data: [],
          name: "",
          type: "line",
          smooth: true,
        };
        seriesItem.name = campaign.name;
        // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
        let campaignxAxis = [];
        if (campaign.trend.length !== 0) {
          // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
          const startTimeIndex = this.chartData.echartxAxis.data.indexOf(
            campaign.trend[0].xAxis
          );
          const endTimeIndex = this.chartData.echartxAxis.data.indexOf(
            campaign.trend[campaign.trend.length - 1].xAxis
          );
          campaignxAxis = this.chartData.echartxAxis.data.slice(
            startTimeIndex,
            endTimeIndex + 1
          );
        }
        console.log(this.chartData.echartxAxis.data);
        seriesItem.data = this.chartData.echartxAxis.data.map((xAxis) => {
          console.log(xAxis);
          let data = 0;
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign.trend.find((item) => item.xAxis === xAxis);
            trend ? (data = trend.yAxis) : (data = 0);
          }
          return data;
        });
        console.log(seriesItem);
        return seriesItem;
      });
    },
    timeSel(val) {
      this.echartsTime = val;
      this.getProductTrend();
    },
    seeTotalChart(a, b) {
      console.log(b);
      this.yType = b;
      this.trendType = b;
      this.getProductTrend();
    },
    seeBrandChart(a, b) {
      console.log(b);
      this.yType = b;
      this.trendType = b;
      this.getProductTrend();
    },
    setSelPlatform(platform) {
      this.selPlatform = platform;
      this.kolContentTableDataCopy = [];
      this.page = 1;
      this.getProductArticleTop();
    },
    clickHotWords(item) {
      this.hotword = item;
      this.kolContentTableDataCopy = [];
      this.page = 1;
      this.getProductArticleTop();
    },
    changeSeg(val) {
      if (val) {
        this.segValue = val;
        this.kolContentTableDataCopy = [];
        this.getProductArticleTop();
      }
    },
    changeGeneralSeg(val) {
      console.log("hahaha");
      if (val) {
        this.generalViewSegValue = val;
        this.getSovProductPlatformTaFunc();
      }
    },
    // changeSegTa (val) {
    //   if (val) {
    //     this.segValue
    //   }
    // },
    loadMoreData() {
      if (this.page * this.size > this.total) {
        this.$message({
          type: "info",
          message: "没有更多数据了!",
        });
        return;
      }
      this.page++;
      this.getProductArticleTop(true);
    },
    downContent() {
      this.btnLoadingflag = false;
      downLoadProductArticleReport({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.id,
        searchWord: "",
        hotword: "",
        platform: this.selPlatform,
        seg: this.productSeg,
        // aggMonth: this.timeRange[1],
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "正在下载，请耐心等待",
          });
          this.btnLoadingflag = true;
        })
        .catch(() => {
          this.$message.error("下载失败，请联系管理员");
        });
    },
  },
  watch: {
    productSeg() {
      this.kolContentTableDataCopy = [];
      this.getProductArticleTop();
      this.getSovProductPlatformTaFunc();
    },
    timeRange(val) {
      console.log(val);
      if (val.length !== 0) {
        this.init();
      }
    },
  },
  components: {
    sovTitle,
    sovGeneralView,
    productTa,
    sovDistribute,
    sovChartCart,
    sovKolContent,
    staggerXBar,
  },
};
</script>

<style lang="scss" scoped>
.filterBoxFixed {
  width:20%;
  position: sticky;
  top:0;
  height: 100%;
}
.filterRightBlock{
  width:80%;
}
.sovProductWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap:20px;
  // height: 100%;
}
</style>
