<template>
  <div class="contentWrap">
    <div class="publicStyle">
      <p class="publicTitleStyle">
        {{ title }}
      </p>
      <div style="width: 100%">
        <div class="hotWords">
          <div class="product">
            <div class="hotWordTitleClass">
              产品内容热词
              <el-tag
                style="margin-left: 10px"
                @close="handleClose(tag)"
                v-for="tag in tags"
                :key="tag.name"
                closable
                :type="tag.type"
              >
                {{ tag.name }}
              </el-tag>
              <!-- <span @click="allContent" class="content all">全部内容</span> -->
            </div>

            <echarts-word-cloud
              class="headBox"
              :words="productHotwordsList"
              @clickHotWords="clickHotWord"
            />
          </div>
          <div class="comment">
            <div class="hotWordTitleClass">用户评论热词</div>
            <echarts-word-cloud
              class="headBox"
              :words="commentHotwordsList"
              :isProuctHotWord="false"
              @clickHotWords="clickHotWord"
            />
          </div>
        </div>
      </div>

      <div class="kolContentTable">
        <div class="segBox">


          <div class="segClass">
<!--            <svg-icon-->
<!--              class-name="iconuser"-->
<!--              :style="{-->
<!--                fontSize: '22px',-->
<!--                color: '#dda634',-->
<!--                verticalAlign: 'middle',-->
<!--                marginRight: '10px',-->
<!--              }"-->
<!--            />-->
            <span
              :style="{
              fontSize:'14px',
                marginRight: '10px',
              }"
              >目标人群</span
            >
            <el-select
              v-model="segValue"
              size="mini"
              class="m-2 autoWidth"
              @change="changeSeg"
              placeholder="请选择人群"
            >
              <template #prefix>
                {{ crowdListComputed.filter(i => i.key === segValue)[0].name }}
              </template>
              <el-option
                v-for="item in crowdListComputed"
                :key="item.key"
                :label="item.name"
                :value="item.key"
              />
            </el-select>
            <div>
              <svg-icon
                  icon-class="downloadIcon"
                  class-name="downloadIcon"
              ></svg-icon>
              <el-button
                  :loading="btnLoading"
                  class="noborderBtn "
                  type="text"
                  style="margin-right:0;color:#000;"
                  @click="downContent"
              >
                下载
              </el-button>
            </div>
          </div>
        </div>

        <el-table
          height="520"
          :data="tableData"
          v-loading="articleTopLoading"
          style="max-height: 450px; overflow: auto"
        >
          <el-table-column
            prop="brandName"
            align="center"
            width="100"
            label="品牌"
            fixed
          >
          </el-table-column>
          <el-table-column
            prop="productName"
            align="center"
            show-overflow-tooltip
            width="160"
            label="产品"
            fixed
          >
          </el-table-column>
          <el-table-column
            prop="kolName"
            align="center"
            show-overflow-tooltip
            width="100"
            label="KOL名称"
            fixed
          >
          </el-table-column>
          <el-table-column
            prop="platform"
            align="center"
            width="130"
            label="平台"
            fixed
          >
            <template #header>
              <el-select v-model="selPlatform" placeholder="请选择">
                <el-option
                  v-for="item in platform"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
            <template #default="{ row }">
              {{ platformToZn(row.platform) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="tier"
            align="center"
            show-overflow-tooltip
            label="KOL级别"
          >
            <template #default="{ row }">
              {{
                row.tier === 0
                  ? "KOC"
                  : row.tier === 1
                  ? "Tier1"
                  : row.tier === 2
                  ? "Tier2"
                  : row.tier === 3
                  ? "Tier3"
                  : "--"
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="kolFans"
            width="130"
            align="center"
            label="粉丝量"
          >
            <template #default="{ row }">
              {{ sliceDecimal(row.kolFans) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="taRatioPct"
            align="center"
            show-overflow-tooltip
            label="TA浓度%"
            width="100"
          >
            <template #default="{ row }">
              {{ row.taRatioPct === null ? "--" : row.taRatioPct }}
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            prop="postDate"
            align="center"
            label="发文日期"
          >
          </el-table-column>
          <el-table-column
            prop="link"
            align="center"
            show-overflow-tooltip
            width="200"
            label="文章链接"
          >
            <template #default="{ row }">
              <a
                v-if="row.title"
                target="_blank"
                style="color: #51a6f0"
                :href="row.link"
                >{{ row.title }}</a
              >
              <a
                target="_blank"
                style="color: #51a6f0"
                :href="row.link"
                v-else
                >{{ row.link === null ? "--" : row.link }}</a
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="exposure"
            align="center"
            width="130"
            label="阅读量"
          >
            <template #default="{ row }">
              {{ sliceDecimal(row.exposure) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="engagement"
            width="120"
            align="center"
            label="互动量"
          >
            <template #default="{ row }">
              {{ sliceDecimal(row.engagement) }}
            </template>
          </el-table-column>
          <el-table-column prop="waterPct" align="center" label="水量%">
            <template #default="{ row }">
              {{ row.waterPct === null ? "--" : row.waterPct }}
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            width="120"
            align="center"
            label="投放费用"
          >
            <template #default="{ row }">
              {{ row.price === null ? "--" : "￥" + sliceDecimal(row.price) }}
            </template>
          </el-table-column>
          <el-table-column prop="cpm" align="center" label="CPM">
            <template #default="{ row }">
              {{ row.cpm === null ? "--" : "￥" + sliceDecimal(row.cpm) }}
            </template>
          </el-table-column>
          <el-table-column prop="cpe" align="center" label="CPE">
            <template #default="{ row }">
              {{ row.cpe === null ? "--" : "￥" + sliceDecimal(row.cpe) }}
            </template>
          </el-table-column>
          <el-table-column prop="taCpm" align="center" label="TA CPM">
            <template #default="{ row }">
              {{ row.taCpm === null ? "--" : "￥" + sliceDecimal(row.taCpm) }}
            </template>
          </el-table-column>
          <el-table-column prop="taCpe" align="center" label="TA CPE">
            <template #default="{ row }">
              {{ row.taCpe === null ? "--" : "￥" + sliceDecimal(row.taCpe) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import echartsWordCloud from "@/components/echarts/echarts_wordCloud";
import storage from "@/utils/storage";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      brandRadio: "",
      searchInput: "",
      segValue: "32_1",
      platform: [
        {
          label: "全平台",
          value: "all",
        },
        {
          label: "微信",
          value: "wechat",
        },
        {
          label: "微博",
          value: "weibo",
        },
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "小红书",
          value: "xiaohongshu",
        },
        {
          label: "哔哩哔哩",
          value: "bilibili",
        },
      ],
      selPlatform: "all",
      btnLoading: false,
      tags: [],
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    brandTab: {
      type: String,
      default: "",
    },
    productHotwordsList: {
      type: Array,
      default() {
        return [];
      },
    },
    commentHotwordsList: {
      type: Array,
      default() {
        return [];
      },
    },
    articleTopLoading: {
      type: Boolean,
      default: false,
    },
    btnLoadingflag: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    let doms = document.querySelector(".contentWrap .el-table__body-wrapper");
    doms.addEventListener("scroll", this.func(this.scrollHandle, 1000));
  },
  methods: {
    ...mapMutations({
      setProductSeg: "SET_PRODUCTSEG",
    }),
    changeSeg(val) {
      this.setProductSeg(val);
    },
    formatCrowdList(list = []) {
      let tempList = [];
      list.forEach((v) => {
        if (v.key !== null) {
          tempList.push(v);
        }
      });
      return tempList;
    },
    func(func, delay) {
      var timer = null;
      return () => {
        var args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            func.apply(this, args);
            timer = null;
          }, delay);
        }
      };
    },
    scrollHandle() {
      console.log(
        document.querySelector(".contentWrap .el-table__body-wrapper").scrollTop
      );
      console.log(
        document.querySelector(".contentWrap .el-table__body-wrapper")
          .scrollHeight
      );
      let timeOut = null;
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        if (
          document.querySelector(".contentWrap .el-table__body-wrapper")
            .scrollTop +
            620 >
          document.querySelector(".contentWrap .el-table__body-wrapper")
            .scrollHeight
        ) {
          this.$emit("loadMoreData");
        }
      }, 400);
    },
    handleSearch() {
      this.$emit("handleSearch", this.searchInput);
    },
    searchBtn() {
      this.$emit("searchBtn", this.searchInput);
    },
    downContent() {
      this.btnLoading = true;
      this.$message({
        type: "info",
        message: "文件正在解析，请耐心等待",
      });
      this.$emit("downContent");
    },
    allContent() {
      this.$emit("clickHotWords", "");
    },
    handleClose() {
      this.tags = [];
      this.allContent();
    },
    clickHotWord(word) {
      this.tags = [];
      this.tags.push({
        name: word,
        type: "",
      });
      this.$emit("clickHotWords", word);
    },
  },
  computed: {
    ...mapState(["productSeg"]),
    crowdListComputed() {
      return this.formatCrowdList(storage.getItem("corwdListStorage"));
    },
  },
  watch: {
    productSeg(val) {
      this.segValue = val;
    },
    brandTab: {
      handler(val) {
        this.brandRadio = val;
      },
      immediate: true,
    },
    selPlatform() {
      this.$emit("setSelPlatform", this.selPlatform);
    },
    btnLoadingflag(val) {
      if (val) {
        this.btnLoading = false;
      }
    },
  },
  components: {
    echartsWordCloud,
  },
};
</script>

<style lang="scss" scoped>
.publicStyle {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.content {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-left: 20px;
  z-index: 1000;
  color: #ccc;
}

.hotWords {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 350px;
  .hotWordTitleClass {
    font-size: 16px;
    height: 32px;
    line-height: 32px;
  }
  .product {
    width: 45%;
    height: 335px;
  }
  .comment {
    width: 45%;
    height: 335px;
  }
}

.all {
  width: 80px;
  height: 28px;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.btnPublic {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
  border-radius: 4px;
  color: #fff;
}

.publicTitleStyle {
  overflow: hidden;
  padding-top: 20px;
  padding-left: 20px;
  font-size: 20px;
  color: #4f4f4f;
}

.headBox {
  height: 300px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 10px 20px 20px;
  border-radius: 15px;
}
</style>

<style lang="scss">
.contentWrap {
  .selectClass {
    .el-scrollbar__view.el-select-dropdown__list {
      margin-top: 5px;
    }

    .el-select__caret.el-input__icon.el-icon-arrow-up {
      line-height: 28px;
    }

    font-size: 14px;

    .el-input__inner {
      width: 285px;
      height: 28px;
    }

    .el-input--suffix {
      height: 35px;
      line-height: 35px;
    }
  }

  .firstRowStyle {
    td div {
      font-size: 18px;
      color: #393a4d;
      font-weight: 400;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  .rowStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  .el-radio__input.is-checked .el-radio__inner {
    background-color: #fff;
    border-color: #dda634;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #dda634;
  }

  .el-radio__inner:hover {
    border-color: #dda634;
  }

  .el-radio__inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
  }

  .el-radio__inner::after {
    width: 10px;
    height: 10px;
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  }

  .el-radio {
    margin-right: 20px;
  }

  .distributeTable
    .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
  }

  .el-table__expanded-cell[class*="cell"] {
    background-color: #f9fcff;
    padding: 0;
    padding-left: 90px;
  }

  .is-active {
    .el-radio-button__inner {
      background-color: #e09a29 !important;
      border-color: #e09a29 !important;
      color: #fff !important;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    box-shadow: -1px 0 0 0 #e09a29;
  }

  .el-radio-button {
    .el-radio-button__inner {
      background-color: #f8f9fe;
      color: #979797;
    }
  }

  .el-input {
    .el-input__inner {
      height: 100%;
    }
  }

  .kolContentTable {
    margin-top: 20px;
    .segClass {
      display:flex;
      justify-content: flex-end;
      gap:10px;
      align-items: center;
      margin-right:30px;
      .el-select .el-input__suffix {
        top: 0 !important;
      }
    }

    .el-input--suffix .el-input__inner {
      height: 30px;
    }

    .el-input__suffix {
      top: 5px;
    }

    .el-select .el-input__suffix {
      top: -4px;
    }

    .el-input--suffix .el-input__icon {
      line-height: 30px;
    }
  }
}
</style>
