<template>
  <div class="lineDiv" ref="chart"></div>
</template>

<script>
/*
 * 传x轴(日期)，默认为空
 * 传Y轴（数据），默认为空
 * */

import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([LineChart, TooltipComponent, GridComponent, CanvasRenderer]);
//对Proxy对象进行拆箱。
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
// import { nextTick } from "vue";

export default {
  name: "line-bar",
  data() {
    return {
      myChart: "",
      timer: null,
      defaultColors: [
        "#2c7d98",
        "#d1eef8",
        "#f0a3b6",
        "#a02947",
        "#06c1ff",
        "#65aeb1",
        "#bda29a",
        "#ff7c7c",
        "#ffd3d3",
        "#db7cae",
        "#d3baed",
        "#966767",
        "#4fe3ff",
        "#ffa86d",
        "#6797fe",
        "#6c80fe",
        "#ffb57e",
        "#4ebcb4",
      ],
    };
  },
  props: {
    xAxis: {
      type: Object,
      default() {
        return {
          type: "category",
          data: [],
        };
      },
    },
    yAxis: {
      type: Object,
      default() {
        return {
          type: "value",
        };
      },
    },
    series: {
      type: Array,
      default() {
        return [
          {
            type: "line",
            name: "全体网民触达",
            data: [1000],
            smooth: true,
          },
          {
            type: "line",
            name: "全体网民触达",
            data: [100012],
            smooth: true,
          },
        ];
      },
    },
    color: {
      typ: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    formatSeries() {
      return this.series.map((item) => {
        console.log(this.series);
        console.log(item);
        return Object.assign(
          {
            lineStyle: {
              width: 4,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#999", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#eee", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              opacity: 0.1,
            },
          },
          item
        );
      });
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.myChart.resize();
      }, 500);
    });
    this.myChart = echarts.init(this.$refs.chart);
    this.resize();
  },
  beforeUnmount() {
    console.log("beforeDestroy");
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawLineBar() {
      // 解决画布缓存问题
      this.myChart.clear(this.option);
      console.log(this.formatSeries);
      unwarp(this.myChart).setOption({
        grid: {
          show: false,
          top: 30,
          right: 60,
          bottom: 30,
          left: 14,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
        },
        xAxis: Object.assign(
          {
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              margin: 20,
              fontSize: 14,
              formatter: (value) => {
                const i = value.indexOf(" ~ ");
                return i === -1
                  ? value
                  : value.slice(0, i + 3) + "\n" + value.slice(i + 3);
              },
            },
            splitLine: {
              show: false,
            },
          },
          this.xAxis
        ),
        yAxis: Object.assign(
          {
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              margin: 20,
              fontSize: 14,
              formatter: (value) => {
                return value > 1000000
                  ? value / 1000000 + "M"
                  : value > 1000
                  ? value / 1000 + "K"
                  : value;
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#ECE9F1",
              },
            },
          },
          this.yAxis
        ),
        series: this.formatSeries,
        textStyle: {
          fontFamily: "Microsoft YaHei",
          color: "#A2A3A5",
        },
        color: this.color.concat(this.defaultColors),
      });
      this.resize();
    },
    resize() {
      this.myChart.resize();
      window.resize && window.resize();
    },
  },
  watch: {
    series() {
      this.drawLineBar();
    },
  },
};
</script>

<style scoped lang="scss">
.lineDiv {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}
</style>
