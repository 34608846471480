import request, { downloadFile } from "@/utils/axios";

export function getCateTree(params) {
  return request.get("/sov/product/cate/tree", params);
}

export function getCateRankList(params) {
  return request.get("/sov/product/rankinglistV2", params);
}

export function getProductOverview(params) {
  return request.get("/sov/product/overview", params);
}

export function getSovProductComparisonOverview(params) {
  return request.get("/sov/product/comparison/overview", params);
}

export function getProductDistribution(params) {
  return request.get("sov/product/ad/structure", params);
}

// sov/product/comparison/ad/structure
export function getProductComparisonAdStructure(params) {
  return request.get("sov/product/comparison/ad/structure", params);
}

export function getProductTrend(params) {
  return request.get("/sov/product/trend", params);
}

export function getProductArticleTop(params) {
  return request.get("/sov/product/article", params);
}

export function getProductHotwords(params) {
  return request.get("/sov/product/hotwords", params);
}

export function getProductMonth(params) {
  return request.get("/sov/product/month", params);
}

export function downloadGeneralView(params) {
  return downloadFile("/sov/product/report", params, "产品声量概述.xlsx");
}

// /sov/product/article/report
export function downLoadProductArticleReport(params) {
  return downloadFile(
    "/sov/product/article/report",
    params,
    "产品投放内容报告.xlsx"
  );
}

export function getCampaignInsightProfile(params) {
  return request.get("/sov/product/persona", params);
}

// /sov/brand/comparison/persona
export function getSovProductCommparisonPersona(params) {
  return request.get("/sov/product/comparison/persona", params);
}

// /sov/product/platform/ta 单产品投放TA触达效率
export function getSovProductPlatformTa(params) {
  return request.get("/sov/product/platform/ta", params);
}

// /sov/product/comparison/platform/ta 多产品投放TA触达效率
export function getSovProductComparsionPlatformTa(params) {
  return request.get("/sov/product/comparison/platform/ta", params);
}

// // 品牌榜单下载 /sov/brand/rankinglist/report
// export function downLoadSovBrandRankingListReport(params, fileName) {
//   return downloadFile("/sov/brand/rankinglist/report", params, fileName);
// }

// /sov/product/rankinglist/report 产品榜单下载
export function downLoadSovProductRankingListReport(params, fileName) {
  return downloadFile("/sov/product/rankinglist/report", params, fileName);
}

// 获取可选择月份 /sov/product/aggMonth
export function getSovAggMonth() {
  return request.get("/sov/product/aggMonth");
}
