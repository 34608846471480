import moment from "moment";

// 算出初始日期和结束日期
// 返回的是选择月份的第一天 和 传入月份的最后一天

export function setStartTime(timeRange, timeMonth, timeYear) {
  console.log(timeRange);
  console.log(timeMonth);
  console.log(timeYear);

  switch (timeRange) {
    case "1mon":
      return `${timeYear}-${timeMonth}-01`;
    case "3mon":
      if (+timeMonth < 3) {
        return `${+timeYear - 1}-${
          +timeMonth - 2 <= 0 ? +timeMonth - 2 + 12 : +timeMonth - 2
        }-01`;
      } else {
        return `${timeYear}-${+timeMonth - 2}-01`;
      }
    case "6mon":
      if (+timeMonth < 6) {
        return `${+timeYear - 1}-${
          +timeMonth - 5 <= 0 ? +timeMonth - 5 + 12 : +timeMonth - 5
        }-01`;
      } else {
        return `${timeYear}-${+timeMonth - 5}-01`;
      }
    case "12mon":
      if (+timeMonth < 12) {
        return `${+timeYear - 1}-${
          +timeMonth - 11 <= 0 ? +timeMonth - 11 + 12 : +timeMonth - 11
        }-01`;
      } else {
        return `${timeYear}-${+timeMonth - 11}-01`;
      }
    case "thisYear":
      return `${timeYear}-01-01`;
    case "lastYear":
      return `${+timeYear - 1}-01-01`;
    default:
      return `${timeRange}-01`;
  }
}
export function setEndTime(timeRange, timeMonth, timeYear) {
  // 2020-02
  console.log(timeRange);
  console.log(timeMonth);
  console.log(timeYear);
  console.log(timeRange.split("-"));
  if (timeRange === "lastYear") {
    return `${+timeYear - 1}-12-31`;
  } else {
    let tempDay = "";
    if (timeRange.split("-")[1] - 0 === 2) {
      if (
        +timeYear % 4 !== 0 ||
        (+timeYear % 100 === 0 && +timeYear % 400 !== 0)
      ) {
        tempDay = "28";
      } else {
        tempDay = "29";
      }
    } else {
      switch (timeRange.split("-")[1] - 0) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          tempDay = "31";
          break;
        default:
          tempDay = "30";
          break;
      }
    }
    return `${timeRange}-${tempDay}`;
  }
}

export function setStartWeekTime(timeRange, timeMonth, timeYear) {
  let start = "";
  let end = "";
  timeMonth = timeMonth - 0;
  timeYear = timeYear - 0;
  switch (timeRange) {
    case "3mon":
      if (timeMonth - 2 <= 0) {
        timeYear = timeYear - 1;
        timeMonth = timeMonth - 2 + 12;
      } else {
        timeMonth = timeMonth - 2;
      }
      break;
    case "6mon":
      if (timeMonth - 5 <= 0) {
        timeYear = timeYear - 1;
        timeMonth = timeMonth - 5 + 12;
      } else {
        timeMonth = timeMonth - 5;
      }
      break;
    case "12mon":
      if (timeMonth - 11 <= 0) {
        timeYear = timeYear - 1;
        timeMonth = timeMonth - 11 + 12;
      } else {
        timeMonth = timeMonth - 11;
      }
      break;
    case "thisYear":
      timeMonth = 1;
      break;
    case "lastYear":
      timeYear = timeYear - 1;
      timeMonth = 1;
      break;
  }
  let momentTemp = moment([timeYear, timeMonth - 1]);
  if (momentTemp.startOf("week").date() !== 1) {
    start = momentTemp.startOf("week").add(7, "day").format("YYYY-MM-DD");
    end = momentTemp.startOf("week").add(14, "day").format("YYYY-MM-DD");
  } else {
    start = momentTemp.startOf("week").format("YYYY-MM-DD");
    end = momentTemp.startOf("week").add(7, "day").format("YYYY-MM-DD");
  }
  console.log(end);
  return `${start} ~ ${end}`;
}

export function setEndWeekTime(timeRange, timeMonth, timeYear) {
  let start = "";
  if (timeRange === "lastYear") {
    timeYear = timeYear - 1;
    timeMonth = 12;
  }
  let momentTemp = moment([timeYear, +timeMonth - 1]);
  let temp = momentTemp
    .endOf("month")
    .add(6 - momentTemp.endOf("month").weekday(), "day");
  let end = temp.format("YYYY-MM-DD");
  start = temp.subtract(7, "day").format("YYYY-MM-DD");
  return `${start} ~ ${end}`;
}

export function setStartMonthTime(timeRange, timeMonth, timeYear) {
  timeMonth = timeMonth - 0;
  timeYear = timeYear - 0;
  switch (timeRange) {
    case "1mon":
      return `${timeYear}-${timeMonth}`;
    case "3mon":
      if (timeMonth - 2 <= 0) {
        timeYear = timeYear - 1;
        timeMonth = timeMonth - 2 + 12;
      } else {
        timeMonth = timeMonth - 2;
      }
      return `${timeYear}-${timeMonth}`;
    case "6mon":
      if (timeMonth - 5 <= 0) {
        timeYear = timeYear - 1;
        timeMonth = timeMonth - 5 + 12;
      } else {
        timeMonth = timeMonth - 5;
      }
      return `${timeYear}-${timeMonth}`;
    case "12mon":
      if (timeMonth - 11 <= 0) {
        timeYear = timeYear - 1;
        timeMonth = timeMonth - 11 + 12;
      } else {
        timeMonth = timeMonth - 11;
      }
      return `${timeYear}-${timeMonth}`;
    case "thisYear":
      return `${timeYear}-1`;
    case "lastYear":
      return `${timeYear - 1}-1`;
  }
}

export function setEndMonthTime(timeRange, timeMonth, timeYear) {
  timeMonth = timeMonth - 0;
  timeYear = timeYear - 0;
  return timeRange === "lastYear"
    ? `${timeYear - 1}-1`
    : `${timeYear}-${timeMonth}`;
}
