<template>
  <div class="card">
    <el-card
      :body-style="{
        height: '460px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }"
      style="margin-bottom: 20px; border-radius: 10px"
    >
      <p class="item_title">{{ title }}
        <el-radio-group
            v-model="echartsTime"
            size="mini"
            :class="{ cancelMargin: !isShowCategory }"
            style="margin-right: 28px; float: right"
        >
          <el-radio-button label="day">日</el-radio-button>
          <el-radio-button label="week">周</el-radio-button>
          <el-radio-button label="month">月</el-radio-button>
        </el-radio-group>
      </p>
      <div style="display: flex; justify-content: space-between">
        <div style="flex: 1; display: flex; align-items: flex-start">
          <el-select
            v-if="isShowSelect"
            v-model="campaignFilter"
            multiple
            placeholder="请选择"
            size="mini"
            collapse-tags
            @change="handleFilter"
            class="dash_board_select"
          >
            <el-option
              v-for="item in campaignListOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div
            style="flex: 1; display: flex; flex-wrap: wrap; margin-left: 20px"
          >
            <legend-com
              v-for="legend in echartLegend"
              :key="legend.name"
              :data="legend"
            />
          </div>
        </div>
        <div class="divWrap">
          <div style="width: 100%">

            <!-- <span
            @click="seeTotalChart"
            :class="{ displayClass: !isShowCategory }"
            v-if="!isShowCate"
            class="showCategory"
            >查看CPI<i class="noteClass el-icon-notebook-2"></i
          ></span>
          <span @click="seeTotalChart" v-else class="showCategory showCate"
            >查看产品<i class="noteClass el-icon-notebook-2"></i
          ></span> -->
          </div>
          <div>
            <el-radio-group
              v-model="echartsRadio"
              size="mini"
              style="margin-top: 10px"
            >
              <el-radio label="exposure">曝光量</el-radio>
              <el-radio label="engagement">互动量</el-radio>
              <el-radio label="pgcNum">投放文章数</el-radio>
              <el-radio label="cpm">CPM</el-radio>
              <el-radio label="cost">投放费用</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <line-bar
        :xAxis="chartData.echartxAxis"
        :series="chartData.echartSeries"
        :color="chartData.echartColor"
      />
    </el-card>
  </div>
</template>

<script>
import lineBar from "@/views/sov/sovCommonComponents/lineBar";
import legendCom from "@/views/sov/sovCommonComponents/legendCom";

export default {
  data() {
    return {
      echartsTime: "day",
      campaignFilter: [],
      campaignListOption: [],
      isShowCate: false,
      echartsRadio: "exposure",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    isShowCategory: {
      type: Boolean,
      default: true,
    },
    chartData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    echartLegend: {
      type: Array,
      default: () => {
        return [];
      },
    },
    campaignList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isProduct: {
      type: Boolean,
      default: false,
    },
    isShowSelect: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.campaignFilter = this.campaignList.map((item) => item.value);
    this.campaignList.forEach((v) => {
      if (v.label !== "全部") {
        this.campaignListOption.push(v);
      }
    });
  },
  methods: {
    handleFilter() {
      if (this.isShowCate) {
        this.$emit("seeTotalChart", this.campaignFilter,this.echartsRadio);
      } else {
        this.$emit("seeBrandChart", this.campaignFilter,this.echartsRadio);
      }
    },
    seeTotalChart(type) {
      this.isShowCate = !this.isShowCate;
      console.log(this.campaignFilter);
      if (this.isShowCate) {
        this.$emit("seeTotalChart", this.campaignFilter, type);
      } else {
        this.$emit("seeBrandChart", this.campaignFilter, type);
      }
    },
  },
  watch: {
    echartsTime(val) {
      if (this.isProduct) {
        console.log(this.isProduct);
        this.$emit("proTimeSel", val);
      } else if (this.isShowCate) {
        console.log(this.isShowCate);
        this.$emit("timeSel", val);
      } else {
        console.log("brand");
        this.$emit("timeSel", val);
      }
    },
    echartsRadio(val) {
      console.log(val);
      if (val === "cpi") {
        this.seeTotalChart(val);
      } else {
        this.seeTotalChart(val);
      }
    },
  },
  components: {
    lineBar,
    legendCom,
  },
};
</script>

<style lang="scss" scoped>
.item_title {
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4f4f4f;
}

.showCategory {
  cursor: pointer;
  display: inline-block;
  width: 92px;
  height: 28px;
  line-height: 28px;
  background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
  border-radius: 4px;
  padding-left: 10px;
  color: #fff;

  .noteClass {
    position: relative;
    left: 8px;
    color: #fff;
    font-size: 16px;
    top: -1px;
  }
}

.displayClass {
  display: none;
}

.showCate {
  width: 92px;
}

.cancelMargin {
  margin-right: 0 !important;
}
.divWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
.card {
  .selectClass {
    .el-scrollbar__view.el-select-dropdown__list {
      margin-top: 5px;
    }

    .el-select__caret.el-input__icon.el-icon-arrow-up {
      line-height: 28px;
    }

    font-size: 14px;

    .el-input__inner {
      width: 285px;
      height: 28px;
    }

    .el-input--suffix {
      height: 35px;
      line-height: 35px;
    }
  }

  .firstRowStyle {
    td div {
      font-size: 18px;
      color: #393a4d;
      font-weight: 400;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  .rowStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  //.el-radio__input.is-checked .el-radio__inner {
  //  background-color: #fff;
  //  border-color: #dda634;
  //}
  //
  //.el-radio__input.is-checked + .el-radio__label {
  //  color: #dda634;
  //}
  //
  //.el-radio__inner:hover {
  //  border-color: #dda634;
  //}
  //
  //.el-radio__inner {
  //  width: 20px;
  //  height: 20px;
  //  background-color: #fff;
  //}
  //
  //.el-radio__inner::after {
  //  width: 10px;
  //  height: 10px;
  //  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  //}

  .el-radio {
    margin-right: 20px;
  }

  .distributeTable
    .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
  }

  .el-table__expanded-cell[class*="cell"] {
    background-color: #f9fcff;
    padding: 0;
    padding-left: 90px;
  }

  //.is-active {
  //  .el-radio-button__inner {
  //    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%) !important;
  //    border-color: #e09a29 !important;
  //    color: #fff !important;
  //  }
  //}
  //
  //.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  //  box-shadow: -1px 0 0 0 #e09a29;
  //}
  //
  //.el-radio-button {
  //  .el-radio-button__inner {
  //    background-color: #f8f9fe;
  //    color: #979797;
  //  }
  //}

  .el-select.dash_board_select.el-select--mini {
    width: 220px;
  }
}
</style>
