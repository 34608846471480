<template>
  <div class="sovTitle">
    <div class="filter">
      <div class="left_block">
        <div class="title_block">
          <p>{{ typeName }}</p>
        </div>
        <div class="info_block">
          <div class="logo_block" v-if="this.type === 'brand'">
            <img :src="logo"/>
          </div>
          <img src="../sovProduct/logo.png" style="width:20px;" v-else/>
          <p> <span class="ellipsis">{{
              type === "brand"
                  ? brand
                  : type === "product"
                      ? product
                      : type === "category"
                          ? category
                          : ""
            }}</span></p>
        </div>
        <div class="date_block">
          <span>{{ cardTitle }}</span>
          <el-date-picker
              v-model="timeRange"
              type="monthrange"
              unlink-panels
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              value-format="YYYY-MM"
              :disabled-date="disabledDate"
              :clearable="false"
              size="small"
          >
          </el-date-picker>
        </div>
      <div>
<!--        <svg-icon class-name="rili" class="riliClass"></svg-icon>-->
    </div>
<!--    <el-button size="mini" @click="forBack" class="returnBtn">返回</el-button>-->
  </div>
    </div>
  </div>
</template>

<script>
import { getSovBrandAggMonth } from "@/api/sov/sovMain";
import storage from "@/utils/storage";
export default {
  data() {
    return {
      optionData: [
        {
          value: "1mon",
          label: "近1个月",
        },
        {
          value: "3mon",
          label: "近3个月",
        },
        {
          value: "6mon",
          label: "近6个月",
        },
        {
          value: "12mon",
          label: "近12个月",
        },
        {
          value: "thisYear",
          label: "今年至今",
        },
        {
          value: "lastYear",
          label: "去年全年",
        },
      ],
      timeRangeArr: "",
      logo:''
    };
  },
  props: {
    cardTitle: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "brand",
    },
  },
  computed: {
    typeName() {
      if (this.type === "brand") {
        return "品牌";
      } else if (this.type === "product") {
        return "产品";
      } else if (this.type === "category") {
        return "品类";
      } else {
        return "";
      }
    },
    brand() {
      return decodeURI(this.$route.query.brand);
    },
    product() {
      return decodeURI(this.$route.query.product);
    },
    category() {
      return decodeURI(this.$route.query.category);
    },
    timeRange: {
      get() {
        return JSON.parse(this.$route.query.duration);
      },
      set(val) {
        if (this.type === "brand") {
          const query = {
            brand: encodeURI(this.brand),
            brandId: this.$route.query.brandId,
            duration: JSON.stringify(val),
          };
          this.$router.push({ name: "sovBrand", query });
        } else if (this.type === "product") {
          const query = {
            product: encodeURI(this.product),
            productId: this.$route.query.productId,
            duration: JSON.stringify(val),
          };
          this.$router.push({ name: "sovProduct", query });
        } else if (this.type === "category") {
          const query = {
            category: encodeURI(this.category),
            categoryId: this.$route.query.categoryId,
            duration: JSON.stringify(val),
          };
          this.$router.push({ name: "sovCategory", query });
        }
      },
    },
  },
  mounted() {
    this.lazyLoad();
    this.timeRangeArr =
      this.$route.path === "/sovProduct"
        ? storage.getItem("productTimeRange")
        : storage.getItem("timeRange");
    this.logo = storage.getItem('logo')
  },
  methods: {
    disabledDate(time) {
      if (this.timeRangeArr) {
        if (this.timeRangeArr.length > 1) {
          return (
            time.getTime() < new Date(this.timeRangeArr[0]).getTime() ||
            time.getTime() >
              new Date(
                this.timeRangeArr[this.timeRangeArr.length - 1]
              ).getTime()
          );
        } else {
          return true;
        }
      }
    },
    async lazyLoad() {
      let res = await getSovBrandAggMonth();
      console.log(res);
      if (res.code === 0) {
        let temp = [];
        res.data.forEach((v) => {
          temp.push({
            value: v,
            label: v,
          });
        });
        this.optionData = [
          {
            value: "zhiding",
            label: "指定月",
            children: temp,
          },
          {
            value: "1mon",
            label: "近1个月",
          },
          {
            value: "3mon",
            label: "近3个月",
          },
          {
            value: "6mon",
            label: "近6个月",
          },
          {
            value: "12mon",
            label: "近12个月",
          },
          {
            value: "thisYear",
            label: "今年至今",
          },
          {
            value: "lastYear",
            label: "去年全年",
          },
        ];
      }
    },
    forBack() {
      if (this.type === "brand") {
        this.$router.push({ name: "sovBrandList" });
      } else if (this.type === "product") {
        this.$router.push({ name: "sovProductList" });
      } else if (this.type === "category") {
        this.$router.push({ name: "sovCategoryList" });
      } else {
        console.log(this.type);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sovTitle {
  font-size: 16px;
  background-color: #fff;
  line-height: 60px;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  color: #11263c;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  align-items: center;

  .filter {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .brandText {
      line-height: 59px;
      color: #11263c;

      span {
        padding: 0 10px;
        border-right: 1px solid #d4d0db;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & :first-child {
        padding-left: 0;
      }

      & :last-child {
        padding-right: 0;
        border-right: none;
      }
    }
  }
  .riliClass {
    margin-right: 5px;
    fill: #e09a29;
  }

  .returnBtn {
    color: #fff;
    width: 74px;
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
    border-radius: 4px;
  }
}
.left_block{
  background: #fff;
  border-radius: 20px;
  height:100%;
  width:100%;
  .title_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:67px;
    border-bottom:1px solid #EDEDED;
    padding:0 20px;
    p{
      font-weight: 500;
      font-size:20px;
      display: flex;
      align-items: center;
    }
  }
  .info_block{
    margin:0 20px;
    border-bottom: 1px solid #ededed;
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    .logo_block{
      width:35%;
      aspect-ratio: 1/1;
      box-shadow: 0px 0px 8.4px 0px #0000001F;
      img{
        width: 100%;
        height:100%;
      }
    }
    p{
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
    }
  }
  .date_block{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding:20px;
    span{
      flex-shrink: 0;
    }
  }
}
</style>

<style lang="scss">
.filter .el-date-editor .el-range-separator {
  width: 9%;
}
</style>
